import classNames from "classnames"
import addToMailchimp from "gatsby-plugin-mailchimp"
import React, { useState } from "react"
import Button from "../components/Button"

const Newsletter = () => {
  const [FNAME, setFNAME] = useState()
  const [email, setEmail] = useState()
  const [message, setMessage] = useState()
  const [disabled, setDisabled] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    setDisabled(true)
    setMessage("Enviando...")
    const response = await addToMailchimp(email, { FNAME: FNAME, "group[54798][1]":"1"})
    if (response.result === "error") {
      if (response.msg.toLowerCase().includes("Ya estás suscrito")) {
        setMessage("Ya estás suscrito a mi boletín!")
      } else {
        setMessage("Ha occurrido un error al suscribirte a mi boletín")
      }
      setDisabled(false)
    } else {
      setMessage(
        "¡Gracias por suscribirte! te he enviado un correo de confirmación."
      )
    }
  }

  return (
    <div className="bg-gray-50">
      <div className="container py-12 lg:py-16">
        <h2 className="text-3xl sm:text-6xl font-bold text-green-800 leading-tight tracking-tight">
          ¡Únete al club!
        </h2>
        <br/>
        <p>
        Aquí podrás encontrar recetas deliciosas, información basada en evidencia científica y artículos sobre temas interesantes.
        </p>
        <form className="mt-8 sm:flex" onSubmit={handleSubmit}>
          <input
            aria-label="Nombre"
            className="appearance-none w-48 sm:max-w-xs px-5 py-3 border border-green-600 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-green-200 transition duration-150 ease-in-out"
            onChange={event => setFNAME(event.target.value)}
            placeholder="Nombre"
            required
            type="text"
          />
          <input
            aria-label="Email address"
            className="appearance-none w-full mt-3 sm:mt-0 sm:ml-3 sm:max-w-xs px-5 py-3 border border-green-600 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-green-200 transition duration-150 ease-in-out"
            onChange={event => setEmail(event.target.value)}
            placeholder="Correo"
            required
            type="email"
          />
          <div className="mt-3 sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <Button disabled={disabled}>Suscribirse</Button>
          </div>
        </form>
        <div
          className={classNames("w-full pt-4 text-sm", { "opacity-0": !message })}
        >
          {message}
        </div>
      </div>
    </div>
  )
}

export default Newsletter
